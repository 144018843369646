<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      
      <!-- <div style="text-align: right; padding: 30px">
        <van-button type="error" @click="showDeleteModal=true">删除11用户</van-button>
      </div> -->
      <div class="tableBox">
        <div style="text-align: right">总行数： {{applicationList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>用户</th>
              <th>交易Hash</th>
              <th>官方收帐钱包</th>
              <th>用户取现钱包</th>
              <th>总量</th>
              <th>总量(含手续费)</th>
              <th>手续费</th>
              <th>状态</th>
              <th>申请日期</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in applicationList" :key="item._id">
              <td>{{ index + 1 }} </td>
              <td>{{ item.user }} </td>
              <td>{{ item.transhferHash.slice(0,10) }}...{{item.transhferHash.slice(-10)}} </td>
              <td><a target="_blank" :href="'https://www.deeperscan.io/deeper/account/'+ item.creditAddress+'#transfers'">{{ item.creditAddress.slice(0,10) }}...{{item.creditAddress.slice(-10)}}</a></td>
              <td><a target="_blank" :href="'https://www.deeperscan.io/deeper/account/'+ item.outAddress+'#transfers'">{{ item.outAddress.slice(0,10) }}...{{item.outAddress.slice(-10)}}</a></td>
              <td>{{ item.total }}.DPR</td>
              <td>{{ item.amount }}.DPR</td>
              <td>{{ item.commission_amount }}.DPR</td>
              <td>{{ item.status | statusMap }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <van-button @click="showPrompt(item._id)">处理</van-button>
              </td>
            </tr>
          </tbody> 
        </table>
      </div>
    </div>
    <van-popup v-model="showDeleteModal">
      <div style="width: 600px;margin: 10px; text-align: center">
        <van-cell-group>
          <van-field label="提现码" v-model="applicationFrom.code"></van-field>        
        </van-cell-group>
      </div>
      <div style="width: 600px;margin: 10px; text-align: center">
        <van-cell-group>
          <van-field label="备注" v-model="applicationFrom.remark"></van-field>        
        </van-cell-group>
      </div>
      <div style="width: 600px; margin: 10px; text-align: center">
        <van-radio-group v-model="applicationFrom.status" direction="horizontal">
          <van-radio name="1">通过</van-radio>
          <van-radio name="0">不过通</van-radio>
        </van-radio-group>
      </div>
      <div style="width: 600px; margin: 10px; text-align: center">
        <van-radio-group>
          <van-button :loading="updateLoadding" block type="primary" @click="updateApplication()" >提交</van-button>
        </van-radio-group>
      </div>
    </van-popup>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import moment from 'moment'

const statusMap = {
  '-1':'未处理',
  '1':'已处理',
  '2':'已处理'
}

export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      applicationList: [],
      showDeleteModal: false,
      updateLoadding: false,
      applicationFrom: {
        id: null,
        status: -1,
        remark: '',
        code: ''
      }
    };
  },
  filters:{
    statusMap: function(value){
      return statusMap[value]
    }
  },

  mounted() {
    this.getApplicationList();
  },
  methods: { 
    getApplicationList() {
      return this.$fetch({
        url: "/crossChains/application",
        headers: {
          Authorization: `${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.applicationList = res.data.map(v=>{
          const { createdAt } =v ;
          return { ...v, createdAt: moment(createdAt).format("YYYY-MM-DD hh:mm:ss") }
        });
      })
    },
    showPrompt(id){
      // this.showDeleteModal = true
      this.showDeleteModal = true
      this.applicationFrom.id = id;
    },
    updateApplication() {
      const data = this.applicationFrom
      const id = this.applicationFrom.id
      this.showDeleteModal = false
      this.$fetch({
        url: `/crossChains/application/${id}`,
        headers: {
          Authorization: `${window.localStorage.getItem("adminToken")}`,
        },
        method: 'put',
        data
      }).then((res) => {
        console.log(res)
      }).finally(()=>{
        this.showDeleteModal = false
      })
    }
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 14px;
  text-align: center;
  table {
    margin-left:auto;
    margin-right:auto;
    border: 2px solid #42b983;
    border-radius: 3px;
    background-color: #fff;
  }

  th {
    background-color: #42b983;
    color: rgba(255,255,255,0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -user-select: none;
  }

  td {
    font-size: 12px;
    background-color: #f9f9f9;
  }

  th, td {
    min-width: 120px;
    padding: 10px 20px;
  }

  th.active {
    color: #fff;
  }

  th.acti

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>